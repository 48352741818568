import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './CTAContactForm.module.scss';

const CTAContactForm = class extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formContentClass: styles.contactFormWrapper,
      formSubmittedClass: styles.contactSubmittedWrapper,
      errorSubmitting: false,
      form: {
        email: '',
        name: '',
      },
    };

    this.markFormSubmitted = this.markFormSubmitted.bind(this);
    this.markErrorSubmitting = this.markErrorSubmitting.bind(this);

    this.nameInput = React.createRef();
    this.emailInput = React.createRef();

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentWillReceiveProps() {
    this.setState({
      formContentClass: styles.contactFormWrapper,
      formSubmittedClass: styles.contactSubmittedWrapper,
      form: {
        email: '',
        name: '',
      },
      errorSubmitting: false,
    });

    this.nameInput.current.value = '';
    this.emailInput.current.value = '';
  }

  markFormSubmitted() {
    this.setState({
      formContentClass: `${styles.contactFormWrapper} ${styles.hidden}`,
      formSubmittedClass: `${styles.contactSubmittedWrapper} ${styles.shown}`,
    });
  }

  markErrorSubmitting() {
    this.setState({
      errorSubmitting: true,
    });
  }

  handleFormSubmit(event) {
    const { office, subject } = this.props;
    event.preventDefault();
    if (process.env.NODE_ENV === 'production') {
      setTimeout(() => {
        window.dataLayer.push({ event: 'cta-submit' });
      }, 50);
    }

    fetch(
      'https://firestore.googleapis.com/v1/projects/lab3-website-1555301298730/databases/(default)/documents/mail',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: {
            to: {
              stringValue:
                office === 'nz'
                  ? `hello.nz@lab3apps.com`
                  : `hello.uk@lab3apps.com`,
            },
            replyTo: {
              stringValue: `${this.emailInput.current.value}`,
            },
            message: {
              mapValue: {
                fields: {
                  subject: {
                    stringValue: subject,
                  },
                  text: {
                    stringValue: `You've received new contact details from a call to action on the Lab3 website page '${window.location.href}'.\nFrom: ${this.nameInput.current.value}\nEmail: ${this.emailInput.current.value}\n`,
                  },
                },
              },
            },
          },
        }),
      }
    ).then(
      () => {
        this.markFormSubmitted();
      },
      () => {
        this.markErrorSubmitting();
      }
    );
  }

  render() {
    const { formContentClass, formSubmittedClass, errorSubmitting } =
      this.state;
    const { color, title, subtitle } = this.props;

    return (
      <div
        className={
          color == 'highlight'
            ? `${styles.contactBox} ${styles.contactBoxHighlight}`
            : `${styles.contactBox}`
        }
      >
        <div className={formContentClass}>
          <div className={styles.title}>{title || 'Interested?'}</div>
          <div className={styles.contactLine} />
          <p className={styles.text}>
            {subtitle || "Give us your details and we'll be in touch."}
          </p>
          <form onSubmit={this.handleFormSubmit} className={styles.contactForm}>
            <label htmlFor="name" className={styles.label}>
              Name:
              <input
                className={styles.field}
                type="text"
                name="name"
                required
                ref={this.nameInput}
              />
            </label>
            <label htmlFor="reply" className={styles.label}>
              Email:
              <input
                className={styles.field}
                type="email"
                name="email"
                required
                ref={this.emailInput}
              />
            </label>
            <input className={styles.submit} type="submit" value="SUBMIT" />
            {errorSubmitting ? (
              <p className={styles.text}>
                An error occurred, please email us at{' '}
                <a href="mailto:hello.nz@lab3apps.com">hello@lab3apps.com</a>
              </p>
            ) : null}
          </form>
        </div>
        <div className={formSubmittedClass}>
          <div className={styles.title}>Thanks</div>
          <div className={styles.contactLine} />
          <p className={styles.text}>We&apos;ll be in touch.</p>
        </div>
      </div>
    );
  }
};

CTAContactForm.defaultProps = {
  office: 'nz',
};

CTAContactForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  office: PropTypes.string,
};

export default CTAContactForm;
