import PropTypes from 'prop-types';
import React from 'react';
import rehypeReact from 'rehype-react';

import CTAContactForm from './CTAContactForm';
import Tooltip from './Tooltip';

const components = {
  // A mapping of tag names (lower case) to Components
  'cta-contact-form': CTAContactForm,
  'lab3-tooltip': Tooltip,
};

function createElement(component, props, children) {
  const Tag =
    (components && component && components[component]) || // Get component from map if present
    component || // Otherwise just the string
    'div'; // Default to div

  // And return the formed component
  return <Tag {...props}>{children}</Tag>;
}

const renderAst = new rehypeReact({
  createElement,
  components: { 'cta-contact-form': CTAContactForm, 'lab3-tooltip': Tooltip },
}).Compiler;

export const HTMLContent = ({ content, className }) => (
  // eslint-disable-next-line react/no-danger
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

export const HTMLContentAST = ({ content, className }) => (
  // eslint-disable-next-line react/no-danger
  <div className={className}>{renderAst(content)}</div>
);

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
);

Content.defaultProps = {
  className: '',
};

Content.propTypes = {
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
};

HTMLContent.propTypes = Content.propTypes;

HTMLContentAST.defaultProps = {
  className: '',
};

HTMLContentAST.propTypes = {
  content: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default Content;
