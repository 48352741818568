import PropTypes from 'prop-types';
import React from 'react';

const Tooltip = class extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { text, tooltip } = this.props;

    return (
      <span
        style={{
          textDecorationStyle: 'dotted',
          textDecorationLine: 'underline',
          cursor: 'help',
        }}
        title={tooltip}
      >
        {text}
      </span>
    );
  }
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default Tooltip;
